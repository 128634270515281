import { Container } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const App: React.FC = () => {

  return (
    <main>
      <Container className="py-4">
        <header className="pb-3 mb-4 border-bottom">
          <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
            <img src="/icons8-chess-player-96.png" alt="icon" style={{ height: '40px' }} />
            <span className="fs-4 ms-2" style={{ color: '#0d6efd' }}>Chessography.com</span>
          </a>
        </header>
        <Outlet />
        <footer className="pt-3 mt-4 text-muted border-top">
          <a target="_blank" rel="noreferrer" href="https://icons8.com/icon/sS3f6ojcSGeb/chess-player">
            Chess Player</a> icon by <a target="_blank" rel="noreferrer" href="https://icons8.com">Icons8
          </a>
        </footer>
      </Container>
    </main>
  );
};

export default App;
