export interface Game {
  uuid: string;
}

export interface GamesResponse {
  games: Game[];
}

export interface GameArchivesResponse {
  archives: string[];
}

export interface Player {
  avatar?: string;
  url: string;
  name?: string;
  username: string;
}

const get = <T>(url: string) =>  new Promise<T>((resolve, reject) => {
  fetch(url)
    .then(response => response.json()
      .then(body => {
        if (response.ok) {
          resolve(body);
        } else {
          reject(body.message as string);
        }
      }));
});

export const getGames = (username: string, yyyy: string, mm: string) =>
  get<GamesResponse>(`https://api.chess.com/pub/player/${username}/games/${yyyy}/${mm}`);

export const getGamesArchives = (username: string) =>
  get<GameArchivesResponse>(`https://api.chess.com/pub/player/${username}/games/archives`);

export const getPlayer = (username: string) =>
  get<Player>(`https://api.chess.com/pub/player/${username}`);