import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormEvent, useState } from "react";
import { Button, Col, Container, Form, InputGroup } from "react-bootstrap";
import { getPlayer } from "./api";
import { PlayerSummary } from "./PlayerSummary";

const PlayerFind: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [player, setPlayer] = useState<any>();
  const [error, setError] = useState<string | undefined>();

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    if (username) {
      getPlayer(username)
        .then(player => {
          setPlayer(player);
          setError(undefined);
        })
        .catch(error => {
          setPlayer(undefined);
          setError(error);
        })
    }
  }

  return (
    <Container fluid className="py-5">
      <h1 className="display-5 fw-bold">Find a player</h1>
      <Col as="p" md={8} className="fs-4">
        Enter the username of a
        {' '}
        <a className="text-decoration-none" target="_blank" rel="noreferrer" href="https://www.chess.com/">Chess.com</a>
        {' '}
        member to find their player profile.
      </Col>
      <Form onSubmit={handleSubmit}>
        <InputGroup size="lg">
          <Form.Control
            autoCapitalize="none"
            autoCorrect="off"
            placeholder="Username"
            type="text"
            isValid={!!player}
            isInvalid={!!error}
            value={username}
            onChange={(event) => setUsername(event.target.value)}
          />
          <Button className="rounded-end" type="submit" variant="outline-secondary">
            <FontAwesomeIcon icon={faSearch} />
          </Button>
          <Form.Control.Feedback className="fs-4 mt-3">
            {player && <PlayerSummary player={player} />}
          </Form.Control.Feedback>
          <Form.Control.Feedback className="fs-4 mt-3" type="invalid">
            {error}
          </Form.Control.Feedback>
        </InputGroup>
      </Form>
    </Container>
  );
};

export default PlayerFind;