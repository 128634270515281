import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Game, getGames } from "./api";

const GameDetail: React.FC = () => {
  const { username, yyyy, mm, uuid } = useParams();
  const [game, setGame] = useState<Game | undefined>();
  
  useEffect(() => {
    if (username && yyyy && mm) {
      getGames(username, yyyy, mm).then(response =>
        setGame(response.games.find(game => game.uuid === uuid)));
    }
  }, [username, yyyy, mm, uuid]);

  return (
    <code style={{ whiteSpace: 'pre' }}>
      {JSON.stringify(game, null, 4)}
    </code>
  );
};

export default GameDetail;
