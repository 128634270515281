import { Stack } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Player } from "./api";

export interface PlayerSummaryProps {
  player: Player;
}

export const PlayerSummary: React.FC<PlayerSummaryProps> = ({
  player,
}) => (
  <Link to={`/player/${player.username}`}>
    <Stack direction="horizontal" gap={3}>
      {player.avatar && <img src={player.avatar} alt="avatar" />}
      <h4>{player.name || player.username}</h4>
    </Stack>
  </Link>
);
