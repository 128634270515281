import { useEffect, useState } from "react";
import { Stack, Tab, Table, Tabs } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { getPlayer, getGamesArchives, Player, getGames, Game } from "./api";
import { PlayerSummary } from "./PlayerSummary";

const PlayerDetail: React.FC = () => {
  const { username } = useParams();
  const [player, setPlayer] = useState<Player | undefined>();
  const [games, setGames] = useState<Game[] | undefined>();
  const [gamesArchives, setGamesArchives] = useState<string[] | undefined>();

  const date = new Date();
  const yyyy = date.getUTCFullYear().toString();
  const mm = (date.getUTCMonth() + 1).toString();

  useEffect(() => {
    if (username) {
      getPlayer(username).then(setPlayer);
      getGames(username, yyyy, mm).then(response => setGames(response.games));
      getGamesArchives(username).then(response => setGamesArchives(response.archives));
    }
  },
    [username, yyyy, mm]);

  return (
    <Stack>
      {player && <PlayerSummary player={player} />}
      <Tabs defaultActiveKey="profile">
        <Tab eventKey="profile" title="Profile">
          <code style={{ whiteSpace: 'pre' }}>
            {JSON.stringify(player, null, 4)}
          </code>
        </Tab>
        <Tab eventKey="archives" title="Archives">
          <code style={{ whiteSpace: 'pre' }}>
            {JSON.stringify(gamesArchives, null, 4)}
          </code>
        </Tab>
        <Tab eventKey="games" title="Games">
          <Table>
            <thead>
              <th>uuid</th>
            </thead>
            <tbody>
              {games?.map(game => (
                <tr key={game.uuid}>
                  <Link to={`games/${yyyy}/${mm}/${game.uuid}`}>{game.uuid}</Link>
                </tr>
              ))}
            </tbody>
          </Table>
        </Tab>
      </Tabs>
    </Stack>
  );
};

export default PlayerDetail;
